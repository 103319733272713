export type DashboardConfiguration = {
  loggedIn?: boolean;
  imprinthasvalue: boolean;
  privacyhasvalue: boolean;
  startupclientid: number;
  globalidmode: number;
  globalidmodetablehead: string;
  guiconfigcontent: string;
  guiconfig: GuiConfig;
  clients: Client[];
  navitems: NavItem[];
  upcomingmeetings: Meeting[];
  pastmeetings: Meeting[];
  recentchangesitems?: RecentChange[]; // Neues Feld hinzugefügt
}

export type GuiConfig = {
  bannerUrl: string,
  logoUrl: string,
  favIcon: string,
  name: string,
  standardcolor: StandardThemeType,
  theme: Theme,
  stylesheetUrl: string,
  pageTitle: string,
}

export type Theme = {
  primaryColor: string,
  primaryColor_lighter: string,
  dark_primaryColor: string,
  dark_primaryColor_darker: string,
  secondaryColor: string,
}

export enum StandardThemeType {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red',
  ORANGE = 'orange',
  PETROL = 'petrol',
  GREY = 'grey',
  BROWN = 'brown',
  PURPLE = 'purple',
}

export const StandardThemeDefinition: { type: StandardThemeType, theme: Theme }[] = [
  {
    "type": StandardThemeType.BLUE,
    "theme": {
      primaryColor: "#68A1DA",
      primaryColor_lighter: "#EEF3FA",
      dark_primaryColor: "#68A1DA",
      dark_primaryColor_darker: "#68A1DA",
      secondaryColor: "#68A1DA",
    }
  },
  {
    "type": StandardThemeType.GREEN,
    "theme": {
      primaryColor: "#25A028",
      primaryColor_lighter: "#EBF7EC",
      dark_primaryColor: "#25A028",
      dark_primaryColor_darker: "#25A028",
      secondaryColor: "#25A028",
    }
  },
  {
    "type": StandardThemeType.RED,
    "theme": {
      primaryColor: "#FF3D31",
      primaryColor_lighter: "#F3E6E6",
      dark_primaryColor: "#FF3D31",
      dark_primaryColor_darker: "#FF3D31",
      secondaryColor: "#FF3D31",
    }
  },
  {
    "type": StandardThemeType.ORANGE,
    "theme": {
      primaryColor: "#E87B16",
      primaryColor_lighter: "#FDF2E8",
      dark_primaryColor: "#E87B16",
      dark_primaryColor_darker: "#E87B16",
      secondaryColor: "#E87B16",
    }
  },
  {
    "type": StandardThemeType.PETROL,
    "theme": {
      primaryColor: "#02A9B4",
      primaryColor_lighter: "#E6F5F7",
      dark_primaryColor: "#02A9B4",
      dark_primaryColor_darker: "#02A9B4",
      secondaryColor: "#02A9B4",
    }
  },
  {
    "type": StandardThemeType.GREY,
    "theme": {
      primaryColor: "#909090",
      primaryColor_lighter: "#EDEDED",
      dark_primaryColor: "#909090",
      dark_primaryColor_darker: "#909090",
      secondaryColor: "#909090",
    }
  },
  {
    "type": StandardThemeType.BROWN,
    "theme": {
      primaryColor: "#9A8772",
      primaryColor_lighter: "#F5F3F2",
      dark_primaryColor: "#9A8772",
      dark_primaryColor_darker: "#9A8772",
      secondaryColor: "#9A8772",
    }
  },
  {
    "type": StandardThemeType.PURPLE,
    "theme": {
      primaryColor: "#A761BE",
      primaryColor_lighter: "#EEEDF0",
      dark_primaryColor: "#A761BE",
      dark_primaryColor_darker: "#A761BE",
      secondaryColor: "#A761BE",
    }
  }
]

export const getThemeByThemeType = (type: StandardThemeType): Theme => {
  const defaultTheme = StandardThemeDefinition.find(themeDef => themeDef.type === StandardThemeType.BLUE)?.theme!;
  const themeDefinition = StandardThemeDefinition.find(themeDef => themeDef.type === type);
  return themeDefinition?.theme || defaultTheme;
}

export type Client = {
  id: number;
  name: string;
}

export type NavItem = {
  id: number;
  text: string;
  url: string;
}

export type Meeting = {
  id: number;
  name: string;
  clientname: string;
  meetingdate: string;
  absolutestarttime: string;
  absoluteendtime: string;
  state: number;
  visibility: number;
  documentcount: number;
}

export type RecentChange = { // Neuer Typ hinzugefügt
  clientId: number;
  clientName: string;
  committeeId: number;
  committeeName: string;
  name: string;
  objectId: string;
  objectType: number;
  objectTypeName: string;
  operation: string;
  operationDateTime: string;
}
